:root {
  --beehive-purple: #8268ff;
  --beehive-purple-transparent-10: #8268ff1a;
  --beehive-purple-transparent-30: #DAD2FF;
  --beehive-purple-light-10: #fef0ff;
  --beehive-purple-light-30: #dad2ff;
  --black: #0F0C0C;
  --grey: #979797;
  --grey-bar: #e9eaef;
  --dark-gray: #5d5d5d;
  --dark-purple: #4a399e;
  --dark-red: #941816;
  --dark-brown: #663c00;
  --text-primary: #0f0c0c;
  --link: #4397f9;
  --avatar-default: #4397f9;
  --error-message: #a94442;
  --error-message-background: #a9444210;
  --gray-disabled: #a6a6a6;
  --grey-transparent-10: #5d5d5d1a;
  --grey-transparent-90: #5d5d5de6;
  --light-gray: #f7f8fd;
  --purple-notification: #dfddfc;
  --light-purple: #c0bbfa;
  --light-purple-50-transparency: #c0bbfa80;
  --light-purple-20-transparency: #c0bbfa30;
  --red-50-transparency: #a9444280;
  --green-active: #2A9D49;
  --green-50-transparency: #2a9d4980;
  --light-yellow: #fff4e5;
  --medium-gray: #E0E0E0;
  --white: #FFFFFF;
  --red-error: #C8211E;
  --red-transparent-10: #FAE9E9;
  --sentiment-green: #2A9D49;
  --sentiment-yellow: #FBC94D;
  --sentiment-orange: #FC7A23;
  --sentiment-red-50-transparency: #a9444280;
  --sentiment-gray: #5d5d5d;
  --notification-purple: #dad9fb;
  --notification-red: #d09e9f;
  --notification-green: #8fcaa2;
  --notification-yellow: #fff4e5;
  --transparent: transparent;
  --divider-color: #E6E6E6;
  --white-half-transparent: #FFFFFF80;
}
