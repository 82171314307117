.errorContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  padding: 16px;
  gap: 12px;
  width: 100%;
  background-color: var(--red-transparent-10);
  border-radius: var(--double-border-radius);
}

.errorText {
  color: var(--dark-red);
  font-family: Rubik;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
}

.errorIcon {
  fill: var(--dark-red);
}
