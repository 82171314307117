.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: var(--white);
}

.App-link {
  color: #09d3ac;
}

/* override drag-n-drop placeholder color */
.react-grid-item.react-grid-placeholder {
  background-color: rgba(117, 84, 243, 0.2) !important;
  pointer-events: none;
}

/* intercom: override launcher position */
div.intercom-launcher, iframe.intercom-launcher-frame {
  margin-left: -10px !important;
}
/* intercom: override messenger position*/
div.intercom-lightweight-app-messenger, div.intercom-messenger-frame {
  left: 70px !important;
  bottom: 20px !important;
}
/* intercom: override quick reply position */
iframe.intercom-borderless-frame {
  bottom: 10px !important;
}
/* intercom: override unread notifications counter */
span[data-testid="active-notifications"], span[data-testid="passive-notifications"] {
  margin-left: -10px !important;
}
