.optionContentLabel {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  margin-right: 16px;
  margin-left: 16px;
  padding-left: 0;
  padding-top: 6px;
  padding-bottom: 6px;

  &.isParent {
    padding-left: 25px;
  }
}

.optionContentSpecial {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 13px 10px;
}

.expandIcon {
  transform: rotateZ(-90deg);

  &.isExpanded {
    transform: rotateZ(0deg);
  }
}

.divider {
  margin: 0;
  flex-shrink: 0;
  border-width: 0;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.12);
  border-bottom-width: thin;
  width: 100%;
}

.optionsWrapper {
  flex-wrap: wrap !important;
  padding: 0 !important;
}
