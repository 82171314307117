.container {
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
}

.dynamicTextarea {
  resize: none;
  width: 100%;
  height: 43px;
  min-height: 1em; /* Roughly one row */
  box-sizing: border-box; /* Includes padding in width */
  overflow: hidden; /* Hides scrollbar initially */
  color: var(--dark-gray);
  background-color: var(--white-half-transparent);
  border-radius: var(--border-radius);
  border: none;
  padding: 10px 8px;
  text-overflow: ellipsis;
  word-wrap: break-word;
  white-space: break-spaces;
  font-family: Rubik;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;

  &:focus {
    outline: none;
  }
}

.closeButtonContainer {
  position: absolute;
  right: 8px;
  top: 10px;
}

.closeButton {
  cursor: pointer;
  width: 16.523px;
  height: 18px;
}
