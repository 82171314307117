.mainContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  border-radius: var(--border-radius);
  background: var(--white);
  padding: 8px;
  border: 1px solid var(--medium-gray);
  cursor: pointer;
}

.mainContainerDisabled {
  cursor: default;
  background: var(--gray-disabled);
}

.expandIcon {
  cursor: pointer;
}

.expandIconDisabled {
  cursor: default;
  fill: var(--dark-gray);
}

.placeholder {
  color: var(--dark-gray);
  text-overflow: ellipsis;
  font-family: Rubik;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
}

.placeholderError {
  cursor: default;
}

.value {
  cursor: pointer;
}

.valueDisabled {
  cursor: default;
}

.optionsContainer {
  position: absolute;
  display: flex;
  flex-direction: column;
  z-index: 1300;

  max-height: 300px;
  overflow: scroll;
  border-radius: var(--border-radius);
  border: 1px solid var(--light-gray);
  background: var(--white);
  box-shadow: 0px 1px 2px 0px rgba(15, 12, 12, 0.30), 0px 2px 6px 2px rgba(15, 12, 12, 0.15);
}

.option {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  padding: 12px 16px;
  cursor: pointer;

  &.isOptionSelected {
    color: var(--beehive-purple);

    &:hover {
      color: var(--white);
    }
  }

  &:hover {
    background-color: var(--beehive-purple-transparent-30);
  }

  &:first-child {
    border-top-left-radius: var(--border-radius);
    border-top-right-radius: var(--border-radius);
  }

  &:last-child {
    border-bottom-left-radius: var(--border-radius);
    border-bottom-right-radius: var(--border-radius);
  }
}

.checkmarkContainer {
  width: 20px;
  height: 20px;
  background-color: var(--beehive-purple);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.checkmark {
  fill: var(--white);
  width: 10px;
  height: 10px;
}
