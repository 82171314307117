.container {
  display: flex;
  align-items: center;
  justify-content: stretch;
  margin-left: 56px;
  min-height: 40px;
  height: auto;
  max-height: 400px;
  border-radius: var(--double-border-radius);
  overflow: hidden;
  background-color: var(--white);
  border: 1px solid var(--beehive-purple);
}

.dynamicTextarea {
  resize: none;
  width: 100%;
  min-height: 40px; /* Roughly one row */
  height: 40px;
  max-height: 400px;
  box-sizing: border-box; /* Includes padding in width */
  overflow: hidden; /* Hides scrollbar initially */
  color: var(--dark-gray);
  background-color: var(--white);
  border-radius: var(--double-border-radius);
  border: none;
  padding: 8px 44px 8px 8px;
  overflow-y: scroll;
  text-overflow: ellipsis;
  word-wrap: break-word;
  white-space: break-spaces;
  /* Body/Default */
  font-family: Rubik;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;

  &:focus {
    outline: none;
  }
}

.actionIcon {
  position: absolute;
  right: 0;
  bottom: 24px;
  width: 24px;
  height: 24px;
  fill: var(--beehive-purple);
  transform: translate(-50%, -50%);
  cursor: pointer;
}
