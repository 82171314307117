.columnHeader {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
}

.columnHeaderMiddle {
  width: 300px;
}

.columnHeaderWide {
  width: 600px;
}

.dateFormatErrorMappingMessageContainer {
  margin-top: 10px;
}

.sourceOptionTitleContainer {
  display: flex;
  gap: 4px;
  align-items: center;
}
