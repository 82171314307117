@import "style/colors.css";
@import "style/sizes.css";

* {
    box-sizing: border-box;
}

body {
    margin: 0;
    font-family: "Rubik", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    background-color: var(--light-gray);

}

text, tspan {
    font-family: "Rubik", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.glowHighlight {
    animation: glow 5s forwards;
    border-radius: 8px;
}

@keyframes glow {
    0% {
        box-shadow: 0 0 8px 8px rgb(125, 104, 255, 0.3);
    }
    100% {
        box-shadow: 0 0 0 0 rgb(125, 104, 255, 0);
    }
}
