.container {
  display: flex;
  width: 600px;
  padding: 32px;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
  position: relative;

  border-radius: var(--double-border-radius);
  background: var(--white);
}

.closeIcon {
  width: 24px;
  height: 24px;
  right: 16px;
  cursor: pointer;
}

.header {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
}

.headerText {
  color: var(--text-primary);
  font-family: Rubik;
  font-size: 32px;
  font-style: normal;
  font-weight: bold;
  line-height: 120%;
}

.body {
  color: var(--text-primary);
  font-family: Rubik;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
}

.footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
  align-self: stretch;
}
